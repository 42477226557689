<template>
  <div class="mainn">
    <ul>
      <li
        v-for="(item, index) in value"
        :key="index"
        :class="{ hang: index % 2 === 0 }"
      >
        <div class="xuzne">
          <!-- <el-button size="small" style="width:200px;" @click="get_staff(index)">{{ item.fzr | get_staff(staff_list) }}</el-button> -->
          <p style="font-size:16px;">负责人</p>
          <div
            class="my_box"
            size="small"
            style="width: 200px"
            @click="get_staff(index, item)"
          >
           
            <li v-for="(ite, idx) in item.fzr" :key="idx">
              <p style="font-size: 14px; width: 100px">
                {{ ite.label }}
              </p>
            </li>
          </div>
          <p>选择桌台</p>
          <el-select
            v-model="item.zuotai"
            multiple
            @change="serarOn_key"
            placeholder="选择桌台"
          >
            <el-option-group
              v-for="group in tableList"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                :disabled="item.dis"
                v-for="item in group.options"
                :key="item.table_id"
                :label="item.table_name"
                :value="item.table_id"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </div>
        <c-icon name="shanchu" @click.native="del(value, index)">删除</c-icon>
      </li>
      <div class="add" @click="add">
        <c-icon name="xinzeng"> </c-icon>新增方案
      </div>
    </ul>
    <c-tree
      :dialogVisible.sync="treeIsshow"
      v-if="treeIsshow"
      :props_istdata.sync="curdata"
      @changeDialog="changeDialog"
    ></c-tree>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    options_zt: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    shop_id: {
      type: String,
    },
  },
  filters: {
    table(val, data) {
      let newval = data.find((x) => {
        return x.table_id === val;
      });
      //if(this.val) {}
      return newval.table_name;
    },
    serar(val, option) {
      let data = [];
      if (val.length === 0) {
        return "";
      }
      let newval = val;
      if (typeof val === "string") {
        newval = val.split(",");
      }
      for (let i = 0; i < newval.length; i++) {
        data.push(option.find((x) => x.staff_id === newval[i]));
      }
      return data.map((x) => x.name).join(",") || "";
    },
  },
  data() {
    return {
      name_fangan: "",
      treeIsshow: false,
      item: null,
      props: {
        value: "id",
        label: "label",
        children: "children",
        multiple: true,
        emitPath: false,
      },
      curdata: [],
      tableList: [],
    };
  },
  watch: {
    value(val) {
      console.log(val);
       this.tableList = this.$props.options_zt;
       this.setDis();
    },
  },

  created() {
    this.tableList = this.$props.options_zt;
    console.log(this.tableList);
    this.setDis();
  },
  mounted() {},
  methods: {
    setDis() {
      this.serarOn_key()
    },
    serarOn_key(val) {
      let arr2 = [];
      this.$props.value.forEach((item) => {
        arr2 = [...arr2, ...item.zuotai];
      });
      for (const i in this.tableList) {
        if (this.tableList[i].options) {
          for (const j in this.tableList[i].options) {
            if (arr2.includes(this.tableList[i].options[j].table_id)) {
              this.tableList[i].options[j].dis = true;
            } else {
              this.tableList[i].options[j].dis = false;
            }
          }
        }
      }
    },
    get_staff(item, val) {
      console.log(item, "点击出来的");
      this.treeIsshow = true;
      this.item = item;
      this.curdata = val?.fzr;
    },
    changeDialog(val) {
      let hash = {};
      this.value[this.item].fzr = val.reduce((item, next) => {
        hash[next.id] ? "" : (hash[next.id] = true && item.push(next));
        return item;
      }, []);
      this.treeIsshow = false;
    },
    delItem(val, index) {
      val.splice(index, 1);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    del(data, index) {
      let self = this;
      let arr2 = [];
      this.$confirm("确认删除").then((res) => {
        data.splice(index, 1);
        this.setDis();
      });
    },
    add() {
      this.value.push({ fzr: [], zuotai: "" });
    },
    input() {
      this.$emit("update:value", this.value);
    },
  },
  updated() {
    this.input();
  },
  computed: {
    ...mapState(["staff_list"]),
  },
};
</script>
<style scoped lang="scss">
$huiseColor: #adadad;
$red-color: rgb(245, 62, 62);
$padding_elCascader: 20px;
.mainn {
  display: flex;
  flex-flow: column nowrap;
  padding: 30px;
  > ul {
    display: flex;
    flex-flow: column nowrap;
    > li {
      width: 100%;
      min-height: 120px;
      padding-top: 10px;
      padding-left: 30px;
      padding-bottom: 8px;
      box-sizing: border-box;
      margin: 0 auto;
      justify-content: center;
      border-radius: 4px;
      position: relative;
      > .contentt {
        top: 10px;
        left: 50%;
        width: 400px;
        height: 100px;
    
        margin-left: 150px;
        vertical-align: middle;
        display: inline-flex;
        border: 2px solid $huiseColor;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        ul {
          padding: 2px;
          width: 380px;
          height: 90px;
          box-sizing: border-box;
          display: inline;
          flex-flow: column wrap;
          > li {
            position: relative;
            padding: 10px;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 10px;
            display: inline-flex;
            border: 1px solid #adadad;
            border-radius: 10px;
            > .c-icon {
              position: absolute;
              top: -3px;
              right: -6px;
              fill: #adadad;
            }
            > span {
              color: #333;
              font-size: 14px;
            }
          }
        }
      }
      > .c-icon {
        position: absolute;
        font-size: 32px;
        text-align: right;
        right: 30px;
        top: 50%;
        margin-top: -16px;
      }
      &.hang {
        background: #f7fdff;
     
      }
      &:hover {
        // background: #eeeeee;
      }
      > .labell {
        width: 200px;
        max-width: 200px;
        margin-top: 10px;
        margin-right: 10px;
        display: inline-flex;
      }
      > .xuzne {
        width: 50%;
        display: inline-flex;
        justify-content: space-around;
        padding-left: 20px;
        > .my_box {
          display: flex;
          max-width: 160px;
          padding: 6px;
          padding-left: 20px;
          min-height: 30px;
          border-radius: 8px;
          border: 1px solid #dcdfe6;
          flex-flow: row wrap;
          &:hover {
            background: #ff56562c;
          }
          > li {
            width: 60px;
            > p {
            }
          }
        }
      }
    }
    > .add {
      display: flex;
      font-size: 30px;
      color: $red-color;
      fill: $red-color;
      height: 110px;
      border: 1px dashed red;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  height: calc(100% + 17px);
}
</style>
