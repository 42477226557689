<template>
  <div class="Enter-box">
    <div class="desktop2">
      <div class="Essential-box1">
        <ul class="Essential-ul1">
          <li @click="$router.push('/DeskManagement')">桌台管理</li>
          <li @click="structure()"  class="Essential-li" >归口管理</li>
          <li @click="TOzhuotaiquyu">区域管理</li>

          <li><p style="width:120px;">当前门店</p>
                  <el-select v-model="shop_id" @change="chang_shop_id" placeholder="选择门店" >
                  <el-option
                    v-for="item in shop_list"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id">
                    <span style="float: left;font-size:14px;">{{item.shop_name}}</span>
                    <span style="float:right;" v-show="flag_bt.indexOf(item.shop_id) === -1">未设置</span>
                    <span style="float:right;" v-show="flag_bt.indexOf(item.shop_id) !== -1">已设置</span>


                  </el-option>
                </el-select>
          </li>
        </ul>
      </div>

      <div class="mains">
        <div class="top">
          <el-radio-group v-model="my_component" size="medium" @change="chang_status_mendian">
            <el-radio label="zrfz">专人负责</el-radio>
            <el-radio label="gzfz">归口负责人负责</el-radio>
          </el-radio-group>
          <!-- <div><el-button type="danger" @click="save_all">保存</el-button></div> -->
        </div>
        <div class="my_main">
        
          <component
            ref="save_list"
            :is="my_component"
            :options="options"
            @save_left="save_left"
            :options_zt="options_zt"
            :shop_id="shop_id"
            @change_bt="change_bt"
          ></component>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// query_shop_info_list 查询店铺信息列表
import { query_dept_staff_tree_aim,query_shop_status_list_of_in_charge, insert_belong_to_rule, query_belong_to_rule, query_shop_status_list_of_bt} from "@api/store"
import { query_table_info_list } from '@api/pulic'
import { mapState ,mapActions} from 'vuex'
import zrfz from "./left"
import gzfz from "./right"
export default {
  computed: {
    ...mapState(['shop_list','ent_id']),
  },
  components: {
    zrfz,
    gzfz
  },
  data() {
    return {
      my_component: 'zrfz',
      options: [],
      options_zt: [],
      shop_id: '',
      list_special: [],
      list_bt: [],
      flag_bt: []
    }
  },
  created() {
    this.get_shop_list() // 
    // this.query_shop_status_list_of_bt()
    this.query_shop_status_list_of_in_charge()
    this.shop_id = this.shop_list[0].shop_id
    this.query_dept_staff_tree_aim()
    this.query_table_info_list()

  },
  // provide(){
  //     return {
  //            change_bt:()=>{
  //       // this.query_shop_status_list_of_bt()
  //       this.changelog()

      
  //   }
  //     }
 
  // },
  methods: {
    ...mapActions(['get_shop_list']), 
    //query_shop_status_list_of_bt
    //获取是否设置完保存
    chang_status_mendian(e){
      e === 'gzfz' && this.query_shop_status_list_of_bt()
      e === 'zrfz' && this.query_shop_status_list_of_in_charge()
    },
    change_bt(e){ 
      e === '归口负责人负责' && this.query_shop_status_list_of_bt()
      e === '专人负责'  && this.query_shop_status_list_of_in_charge()
    },
    // 第一个专人负责人设置
    query_shop_status_list_of_in_charge (){
      // query_shop_status_list_of_in_charge ent_id
      query_shop_status_list_of_in_charge({data:{ent_id:this.ent_id}}).then(res=>{
          if(res.code === '200'){
                this.flag_bt = res.body.data.filter(x=>{
             return   x.flag_bt === '1'
            }).map(x=>x.shop_id)
          }
      })
    },
    // 归口管理
    query_shop_status_list_of_bt(){ // 归口负责人设置
        query_shop_status_list_of_bt({data:{ent_id:this.ent_id}}).then(res=>{
          if(res.code === '200'){
            this.flag_bt = res.body.data.filter(x=>{
             return   x.flag_bt === '1'
            }).map(x=>x.shop_id)
          }
        })

    },
      TOzhuotaiquyu(){
        this.$router.push('/zhuotaiquyu')
      },
    //获取子组件的全部值--要提交的数据
    // save_btn(val){
    //   this.list_bt = val
    // },
    chang_shop_id(val) {
      this.query_table_info_list()
    },
        //查询桌台号
    // query_table_info_list() {
    //   const param = {
    //       ent_id: this.ent_id,
    //       shop_id: this.shop_id
    //   }
    //   query_table_info_list({data:param}).then( res => {
    //     if(res.code === '200'){
    //          this.options_zt  = res.body.data
    //     }
    //   })
    // },
    // 获取部门树
    query_dept_staff_tree_aim() {
      const param = {
        ent_id: this.$store.state.ent_id,
      };
      query_dept_staff_tree_aim({ data: param }).then((res) => {
        if (res.code === "10168") {
          this.options = res.body.data;
        }
      });
    },
        // 获取桌台列表
    query_table_info_list() {
      const param = {
        ent_id: this.ent_id,
        shop_id: this.shop_id
      }
      query_table_info_list({data: param}).then(res => {
        if(res.code === '200'){
          let data = res.body.data

          console.log('桌台列表',data);
          let len = res.body.data.length
             let obj0 = {
                label:'包厢',
                options: []
              }
              let obj1 = {
                label:'小桌',
                options: []
              }
              let obj2 = {
                label: '中桌',
                options: []
              }
              let obj3 = {
                label: '大桌',
                options: []
              }
          for ( let i=0;i<len;i++){
              if(data[i].table_type === '0'){
                obj0.options.push({
                  table_id: data[i].table_id,
                  table_name: data[i].table_name,
                  table_type: data[i].table_type
                })
              }
              if(data[i].table_type === '1'){
                     obj1.options.push({
                  table_id: data[i].table_id,
                  table_name: data[i].table_name,
                  table_type: data[i].table_type
                })
              }
                 if(data[i].table_type === '2'){
                     obj2.options.push({
                  table_id: data[i].table_id,
                  table_name: data[i].table_name,
                  table_type: data[i].table_type
                })
              }
              if(data[i].table_type === '3'){
                     obj3.options.push({
                      table_id: data[i].table_id,
                      table_name: data[i].table_name,
                      table_type: data[i].table_type
                   })
              }
               this.options_zt = [obj0,obj1,obj2,obj3]
          }
        }
      })
    },
    //子组件传过来的值
    save_left(val) {
      this.list_special = val
    }
  }
}
</script>

<style lang="scss">
@import "./css.scss";
</style>

