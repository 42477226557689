<template>
  <div class="right">
    <div class="title">
      <span></span>
      <p>桌台管理</p>
      <div class="titleRight">
        <el-button
          class="titleRightButton"
          size="mini"
          type="danger"
          @click="save_all"
          >归口负责人保存</el-button
        >
      </div>
    </div>
    <div class="coloum">
      <ul>
        <li v-for="(item, index) in listName" :key="index">
          <span
            :class="{ active: my_lisdata.sum === item.sum }"
            @click="changActive(item)"
            >{{ item.name }}</span
          >
        </li>
      </ul>
    </div>
    <MainCont
    v-if="t"
      :options="options"
      :options_zt="options_zt"
      :shop_id="shop_id"
      v-model="my_lisdata.saveData"
    ></MainCont>
  </div>
</template>

<script>
import MainCont from "./main";
 import { mapState } from 'vuex'
// import { query_table_info_list } from '@api/pulic'insert_belong_to_rule
import { query_belong_to_rule, insert_belong_to_rule } from "@api/store";
export default {
  components: {
    MainCont,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    options_zt: {
      type: Array,
      default: () => [],
    },
    shop_id: String,
  },
  computed: {
    ...mapState(["staff_list"]),
    my_lisdata(val) {
      let data;
      if (this.active === "1") {
        data = this.active_ZS;
      } else if (this.active === "2") {
        data = this.active_ZWS;
      } else if (this.active === "3") {
        data = this.active_WS;
      } else if (this.active === "4") {
        data = this.active_WM;
      }
      return data;
    },
  },
  mounted() {
    // 获取到右边的值
    this.get_list_data();
  },
  created() {
    // this.query_table_info_list()
  },
  methods: {
    get_list_data() {
      let { ent_id, shop_id } = this.$parent;
      const param = {
        ent_id,
        shop_id,
      };
      query_belong_to_rule({ data: param }).then((res) => {
        if (res.code === "200") {
          console.log(res, "12313131313");
          let list_bt = res.body.data;
          //  console.log(list_bt,'获取到的归口负责人值');
          this.active_ZS.saveData = list_bt
            .map((res) => {
              if (res.market_type === "1") {
                //  let fzr = []
                return {
                  fzr: obj_array(res.staff_id, this.staff_list),
                  zuotai: res.table_id.split(","),
                };
              }
            })
            .filter(Boolean);
          this.active_ZWS.saveData = list_bt
            .map((res) => {
              if (res.market_type === "2") {
                return {
                  fzr: obj_array(res.staff_id, this.staff_list),
                  zuotai: res.table_id.split(","),
                };
              }
            })
            .filter(Boolean);
          this.active_WS.saveData = list_bt
            .map((res) => {
              if (res.market_type === "3") {
                return {
                  fzr: obj_array(res.staff_id, this.staff_list),
                  zuotai: res.table_id.split(","),
                };
              }
            })
            .filter(Boolean);
          this.active_WM.saveData = list_bt
            .map((res) => {
              if (res.market_type === "4") {
                return {
                  fzr: obj_array(res.staff_id, this.staff_list),
                  zuotai: res.table_id.split(","),
                };
              }
            })
            .filter(Boolean);

          console.log(this.active_ZS.saveData, "这是个啥");
        }
      });
      // 获取人员object array 格式转化
      const obj_array = (staff_id, staff_id_list) => {
        let arr = staff_id.split(",");
        let list = [];
        for (const i of arr) {
          for (const j of staff_id_list) {
            if (i === j.staff_id) {
              j.label = j.name;
              j.id = j.staff_id;
              list.push(j);
            }
          }
        }
        return list;
      };
    },
    // send(){
    //   let data = {
    //       active_ZS:this.active_ZS,
    //       active_ZWS:this.active_ZWS,
    //       active_WS:this.active_WS,
    //       active_WM:this.active_WM
    //   }
    //    this.$emit('save_btn',data)
    // },
    save_all() {
      try {
        let list_bt = [];
        this.active_WM.saveData.forEach((item) => {
          let obj = {};
          obj.market_type = "4";
          obj.staff_id = item.fzr.map((x) => x.id).join(",");
          obj.table_id = item.zuotai.join(",");
          list_bt.push(obj);
        });
        this.active_WS.saveData.forEach((item) => {
          let obj = {};
          obj.market_type = "3";
          obj.staff_id = item.fzr.map((x) => x.id).join(",");
          obj.table_id = item.zuotai.join(",");
          list_bt.push(obj);
        });
        this.active_ZWS.saveData.forEach((item) => {
          let obj = {};
          obj.market_type = "2";
          obj.staff_id = item.fzr.map((x) => x.id).join(",");
          obj.table_id = item.zuotai.join(",");
          list_bt.push(obj);
        });
        this.active_ZS.saveData.forEach((item) => {
          let obj = {};
          obj.market_type = "1";
          obj.staff_id = item.fzr.map((x) => x.id).join(",");
          obj.table_id = item.zuotai.join(",");
          list_bt.push(obj);
        });
        //  if(list_bt.length === 0 || this.list_special ===0){
        //    return  this.$message.warning('请填写完整')
        //  }
        const param = {
          ent_id: this.$store.state.ent_id,
          shop_id: this.shop_id, //门店id
          list_bt,
          //  list_special: this.list_special
        };
        console.warn(param, "这是右边的保存");
        insert_belong_to_rule({ data: param }).then((res) => {
          if (res.code === "200") {
            this.$message.success("保存成功");
            this.get_list_data();
               this.$emit('change_bt','归口负责人负责')
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    changActive(val) {
      this.active = val.sum;
    },
  },
  watch: {

    shop_id(val) {
      this.get_list_data();
      this.t=false
      this.t=true
    },
  },
  data() {
    return {
      t:true,
      active: "1",
      listName: [
        {
          name: "早市",
          sum: "1",
        },
        {
          name: "午市",
          sum: "2",
        },
        {
          name: "晚市",
          sum: "3",
        },
        {
          name: "外卖",
          sum: "4",
        },
      ],
      active_ZS: {
        name: "早市",
        sum: "1",
        saveData: [
          {
            fzr: [],
            zuotai: "",
            hao: [
              {
                id: "A123",
              },
            ],
          },
        ],
      },
      active_ZWS: {
        name: "午市",
        sum: "2",
        saveData: [
          {
            fzr: [],
            zuotai: "",
            hao: [
              {
                id: "A123",
              },
            ],
          },
        ],
      },
      active_WS: {
        name: "晚市",
        sum: "3",
        saveData: [
          {
            title: "方案1",
            fzr: [],
            zuotai: "",
            hao: [
              {
                id: "A123",
              },
            ],
          },
        ],
      },
      active_WM: {
        name: "外卖",
        sum: "4",
        saveData: [
          {
            title: "方案1",
            fzr: [],
            zuotai: "",
            hao: [
              {
                id: "A123",
              },
            ],
          },
        ],
      },
    };
  },
};
</script>
