<template>
  <div class="left">
    <c-tree :dialogVisible.sync="treeIsshow" v-if="treeIsshow" @changeDialog="changeDialog" :props_istdata.sync="curdata"></c-tree>
     <div class="title"><span></span><p>预约管理</p> <div class="titleRight"><el-button  class="titleRightButton" size="mini" type="danger" @click="insert_book_staff_in_charge">保存专人负责</el-button></div></div>
     <div class="box">
    <div class="left-min" v-for="(item,index) in list" :key="index">
        <span>{{item.name}}</span>
        <!-- <div class="my" size="small" style="width:200px;" @click="get_staff(index,item)"><p >{{ item.value | serar(staff_list) }}</p></div> -->
        <div class="my" size="small" style="width:200px;" @click="get_staff(index,item)"><li  v-for="(ite,idx) in item.value" :key="idx">
            <p style="font-size:14px;width:100px;">
              {{ite.label}}
            </p>
          </li>
        </div>
    </div>
     </div>
  </div>
</template>

<script>
import { query_belong_to_rule, insert_book_staff_in_charge, query_book_staff_in_charge_list } from  '@api/store'
 import { mapState ,mapActions} from 'vuex'
import { delete_store_house } from '../../../api/foodqiye'
export default {
  props: {
     shop_id: String,
    // options:{
    //   type:Array,
    //   default:()=>[]
    // }
  },

  // filters: {
  //   serar(val,option) {
  //     let data = []
  //     if(val.length === 0){
  //       return ''
  //     }
  //     let newval = val.split(',')
  //       for(let i = 0; i<newval.length;i++){
  //        data.push( option.find(x=>x.staff_id === newval[i]))
  //       }
  //     return data.map(x=>x.name).join(',') || ''
  //   }
  // },

  // inject: ['ent_id','shop_id'],
  data(){
    return{
      curdata: [],
      treeIsshow:false,
      // props:{
      //     value: 'id',
      //     label: 'label',
      //     children: 'children',
      //     multiple: true,
      //     emitPath: false,
      //     // leaf:'leaf'
      // },
      list:[
        {name:'早市负责人', market_type:'1',value:[]},
        {name:'午市负责人', market_type:'2',value:[]},
        {name:'晚市负责人', market_type:'3',value:[]},
        {name:'外卖负责人', market_type:'4',value:[]},
      ],
      // oldlist:[
      //   {name:'早市负责人', market_type:'1',value:[]},
      //   {name:'午市负责人', market_type:'2',value:[]},
      //   {name:'晚市负责人', market_type:'3',value:[]},
      //   {name:'外卖负责人', market_type:'4',value:[]},
      // ],
      item:null
    }
  },
  methods:{
    // 获取专人负责信息
    query_book_staff_in_charge_list() {
      query_book_staff_in_charge_list({data:{ent_id:this.$store.state.ent_id,shop_id:this.shop_id}}).then(res => {
          if(res.code === '200'){
            let data = []
            let data1 = [
        {name:'早市负责人', market_type:'1',value:[]},
        {name:'午市负责人', market_type:'2',value:[]},
        {name:'晚市负责人', market_type:'3',value:[]},
        {name:'外卖负责人', market_type:'4',value:[]},
      ]
              console.log(res.body.data)
             for(const i of res.body.data){
               let obj = {}
                if(i.market_type === '1'){
                      obj.name = '早市负责人'
                      obj.market_type = i.market_type
                      obj.value = obj_array(i.staff_id,this.staff_list)
                }else if(i.market_type === '2'){
                      obj.name = '午市负责人'
                      obj.market_type = i.market_type
                      obj.value = obj_array(i.staff_id,this.staff_list)
                }else if(i.market_type === '3'){
                      obj.name = '晚市负责人'
                      obj.market_type = i.market_type
                      obj.value = obj_array(i.staff_id,this.staff_list)
                }else if(i.market_type === '4'){
                      obj.name = '外卖负责人'
                      obj.market_type = i.market_type
                      obj.value = obj_array(i.staff_id,this.staff_list)
                }
                data.push(obj)
             }
          
             console.log(data);
         
                this.list = data.length>0? data : data1
             
          }
      })
      const obj_array = (staff_id,staff_id_list) => {
            let arr = staff_id.split(',')
            let list = []
            for (const i of arr){
                for(const j of staff_id_list){
                  if(i === j.staff_id) {
                    j.label = j.name
                    j.id = j.staff_id
                    list.push(j)
                  }
                }
            }
            return list
            
      }
     
    },
    // 保存专人负责
    insert_book_staff_in_charge() {
      let list_special = []
           for(const i of this.list){
              let obj = {}
              obj.market_type = i.market_type
              obj.staff_id = i.value.map(x=>x.id).join(',')
              list_special.push(obj)
           }

      const data = {
          ent_id:this.$store.state.ent_id,
          shop_id:this.shop_id,
          user_id:this.$store.state.user_id,
          list_special
      }
      insert_book_staff_in_charge({data}).then(res=>{
        if(res.code === '200'){
              this.$message.success('保存成功')
              this.$emit('change_bt','专人负责')

           
      
  }
        
      })
    },
    get_staff(item,val){
      this.treeIsshow =  true
      this.item = item
       this.curdata = val?.value
      // console.log(this.curdata)
      // console.log(item,val,'这是item');
    },
     // get_staff_list
     ...mapActions(['get_staff_list']),
     changeDialog(val){
          let hash = {};
         this.list[this.item].value = val.reduce((item, next) => {
          hash[next.id] ? '' : hash[next.id] = true && item.push(next);
          return item
      }, [])
        this.treeIsshow =  false
     },
    tijiao(){
        let  list = this.list.map(x=>{
            return {
              market_type:x.market_type,
              staff_id:x.value
            }
          })
      this.$emit('save_left', list)
    },
    // 获取数据
    get_list_data(){
      let  { ent_id, shop_id } = this.$parent
      const param = {
          ent_id,
          shop_id,
      }
        query_belong_to_rule({data:param}).then(res=>{

           if(res.code === '200'){
             let {list_bt,list_special} = res.body.data
             let list = list_special.map(item => {
               item.market_type === '1' && (item.name="早市负责人")
               item.market_type === '2' && (item.name="午市负责人")
               item.market_type === '3' && (item.name="晚市负责人")
               item.market_type === '4' && (item.name="外卖负责人")
               if(item.staff_id === '[]'){
                item.staff_id =  JSON.parse(item.staff_id )
               }else if(item.staff_id == ''){
                  item.staff_id = []
               }     
                return {
                    name: item.name,
                    market_type: item.market_type,
                    value: item.staff_id
                }
              })
               if(list.length  !== 0){
                 this.list = list
              }else{
                  this.list = this.oldlist

               }

           }
        })
    }
  },
  computed:{
    ...mapState(['staff_list'])
  },
  updated(){
    this.tijiao()
  },
  created(){
    //  this.get_list_data()
     this.get_staff_list()
     this.query_book_staff_in_charge_list()
  },
  watch:{
     shop_id(val){
      //  this.get_list_data()
     this.query_book_staff_in_charge_list()
    }
  }
}
</script>
<style scoped lang="scss">
.left-min{
  display: flex;
  align-items: center;
}
 .my{
   display: flex;
   max-width: 160px;
   padding:6px;
   padding-left: 20px;
   min-height: 30px;
   border-radius: 8px;
   border: 1px solid #DCDFE6;
   flex-flow: row wrap;
   &:hover{
     background: #ff56562c;
   }
   >li{
     width:60px;
    >p{
     
   }
   }

 }  
</style>

